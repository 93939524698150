$(window).load(function () {
  if ($(window).width() <= 767) {
    $(".mobile-slider .grid-items").owlCarousel({
      // slider settings
      autoplay: true,
      items: 1,
      margin: 0,
      center: true,
      loop: true,
    });
  }
});
